<template>
    <nlse-game-row :gameRowTitle="title" :games="games" />
</template>

<script>
import NLSEGameRow from "./NLSEGameRow.vue";
export default {
    components: {
        'nlse-game-row': NLSEGameRow,
    },
    data() {
        return {
            title: 'Upcoming',
            games: [
                {
                    imageSrc: require("../../../../../assets/images/upcoming-games/upcoming_game_1.png"),
                    gameType: "Upcoming Game",
                    leagueInfo: "League Information",
                },
                {
                    imageSrc: require("../../../../../assets/images/upcoming-games/upcoming_game_2.png"),
                    gameType: "Upcoming Game",
                    leagueInfo: "League Information",
                },
                {
                    imageSrc: require("../../../../../assets/images/upcoming-games/upcoming_game_3.png"),
                    gameType: "Upcoming Game",
                    leagueInfo: "League Information",
                },
                {
                    imageSrc: require("../../../../../assets/images/upcoming-games/upcoming_game_4.png"),
                    gameType: "Upcoming Game",
                    leagueInfo: "League Information",
                },
            ],
        }
    },
}
</script>

<style scoped></style>