<template>
    <div class="continer">
        <div class="containera">
            <div class="headline">
                <p class="paragraph-text">
                    Take your sports streaming <br>to the Next Level
                </p>
            </div>
            <p class="signup-statement">
                Sign up now for complete access to the Next Level streaming guide.
            </p>
            <div class="btn-container">
                <button type="button" class="btn btn-warning btn-text">
                    Sign Up Now
                </button>
            </div>
        </div>
    </div>
</template>
    
<script>
export default {
}
</script>
    
<style scoped>
.containera {
    display: inline-block;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    padding-left: 5vw;
    padding-right: 5vw;

}

.headline {
    padding-top: 10vh;
}

.paragraph-text {
    font-size: 3.5vh;
    font-weight: bold;
    font-family: 'Noto Sans', sans-serif;
    margin-left: 1rem;

}

.signup-statement {
    font-size: 2vh !important;
    font-family: 'Noto Sans', sans-serif;
    margin-left: 1rem;
    padding-top: 2.5vw;
}

.btn-container {
    width: 100%;
    padding-left: 1rem;
    padding-top: 3vw;
    padding-bottom: 3vh;
}

.btn-text {
    font-weight: bold;
    font-size: 2vh;
    border-radius: 10px !important;
}

@media (max-width:768px) {
    .containera {
        padding-right: 0;
        margin-left: 0;
    }

    .paragraph-text {
        font-size: 4vh;
    }

    .btn-container {
        padding-top: 5vh;
        padding-left: 0;
    }

    .signup-statement,
    .paragraph-text {
        margin: 0;
    }
}</style>