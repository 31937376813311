<template>
    <div class="row h-50 w-100 m-0 p-3">
        <div style="height: 100%;" class="w-100 m-0 row-item-content d-flex rowcard">
            <span class="game-row-title">{{ gameRowTitle }}</span>
        </div>
        <div style="height: 100%;" class="row w-100 m-0 row-item-content">
            <div style="height: 100%; width: 100%" class="game-card-container" v-for="(gameItem, index) in games"
                :key="index">
                <nlse-game-card :imageSource="gameItem.imageSrc" :gameType="gameItem.gameType"
                    :leagueInfo="gameItem.leagueInfo" :isLive="gameItem.isLive" />
            </div>
        </div>
    </div>
</template>
  
<script>
import NLSEGameCard from "./NLSEGameCard.vue";
export default {
    props: {
        gameRowTitle: {
            type: String,
            default: "",
        },
        games: {
            default: [],
        },
    },
    components: {
        "nlse-game-card": NLSEGameCard,
    },
};
</script>
  
<style scoped>
@media (max-width: 768px) {

    .game-card-container {
        flex: 1;

    }
}

.game-row-title {
    font-weight: bold;
    font-size: 1.3rem;
}

.game-card-container {
    flex: 1;
    margin-right: 1rem;
}

.row-item-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
  