<template>
    <div style="width: 100%" class="cols h-100 m-0 container">
        <div style="width: 100%; height: 100%;">
            <div :style="{ backgroundImage: `url('${imageSource}')` }" class="image-content-card">
                <img v-show="isLive" class="live-image" :src="liveIcon" />
            </div>
        </div>
        <div style="width: 100%; height: 100%;">
            <div class="w-100 m-0 d-flex ">
                <span class="game-type">
                    {{ gameType }}
                </span>
            </div>
            <div class=" w-100 d-flex m-0">
                <span class="league-info">
                    {{ leagueInfo }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        imageSource: {
            type: String,
            default: "",
            required: true,
        },
        isLive: {
            type: Boolean,
            default: false,
            required: true,
        },
        gameType: {
            type: String,
            default: "",
            required: true,
        },
        leagueInfo: {
            type: String,
            default: "",
            required: true,
        },
    },
    data() {
        return {
            liveIcon: require("../../../../../assets/images/live-games/live_icon.png"),
        };
    },
}
</script>

<style scoped>
.container {
    padding-left: 0;
    padding-bottom: 0;
}

.image-container {
    align-items: left;
}

.image-content-card {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 15vw;
    aspect-ratio: calc(9/16);
    position: relative;

}

.live-image {
    position: absolute;
    bottom: 1.5rem;
    left: 1rem;
}

.game-type {
    font-size: 1rem;
    font-weight: bold;
    color: white;
}

.league-info {
    font-size: 1rem;
    font-weight: 600;
    color: lightgray;
    line-height: 1.5;
}

@media (max-width:768px) {
    .live-image {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
}
    .image-content-card {
        height: 20vh;
        width: 100%;
        padding-right: 0;
        background-size: cover;
    }

    .game-type,
    .league-info {
        font-size: 1.3vh;
        padding-right: 10vw;
    }

    .image-container {
        padding: 0;
    }

}</style>