<template>
    <div class="body-continer">
        <div class="display-games ">
            <nlse-pluse-signin />
        </div>
        <div class="display-games ">

            <nlse-games />
        </div>

    </div>
</template>

<script>
import NLSEPlusSignin from "../Components/NLSE/NLSEPlus/NLSEPlusSignin.vue";
import NLSEGames from "../Components/NLSE/NLSEPlus/NLSEGames.vue";
export default {
    components: {
        'nlse-pluse-signin': NLSEPlusSignin,
        'nlse-games': NLSEGames,
    }
}
</script>

<style scoped>

.body-continer {

    background-image: url("../../../assets/images/background/nlse_page_background_image.png");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;

}

.display-games {
    display: flex;
    flex-direction: column;
    /* Change to column */
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    align-items: left;
    justify-content: last baseline;
}

h1 {
    color: #ffffff;
}</style>