<template>
    <div style="height: 80%;" class="w-100">
        <div class="games-upcoming">
            <nlse-live-games></nlse-live-games>
        </div>
        <div class="games-upcoming">
            <nlse-upcoming-games></nlse-upcoming-games>
        </div>
    </div>
</template>

<script>
    import NLSELiveGames from "./NLSELiveGames.vue";
    import NLSEUpcomingGames from "./NLSEUpcomingGames.vue"
    export default {
        components: {
            'nlse-live-games': NLSELiveGames,
            'nlse-upcoming-games': NLSEUpcomingGames,
        }
    }
</script>

<style scoped>
.games-upcoming{
    padding-left: 10vh;

}
@media (max-width: 768px){
    .games-upcoming{
    padding: 0vh;

}
}
</style>